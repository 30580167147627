import { useEffect } from 'react'
import { fetcher } from '../services/http'
import useSWR from 'swr'
import { Session, SessionRevisor } from '../types/session'
import { navigate } from 'gatsby'
import { fetcherAdmin, fetcherRevisor } from '../services/http'

function formatSessionData(data: Session) {
    if (!data) return
    let formattedData: Session = data
    if (data.user) {
        formattedData = {
            isLoggedIn: data.isLoggedIn,
            user: {
                user_id: data.user.user_id,
                name: data.user.name,
                is_mail_verified: Boolean(data.user.is_mail_verified),
                email: data.user.email,
                has_paid: Boolean(data.user.has_paid),
                current_period_end: new Date(data.user.current_period_end),
                feature_flags: data.user?.feature_flags,
                signed_up_at: new Date(data.user.signed_up_at),
            },
        }
    }
    return formattedData
}

function isInstalled() {
    if (typeof window !== 'undefined') {
        // For iOS
        if (window.navigator['standalone']) return true

        // For Android
        if (window.matchMedia('(display-mode: standalone)').matches) return true
    }
    // If neither is true, it's not installed
    return false
}

export const useAuth = (redirectIfFound = '', requiredFeatures: string[] = []) => {
    const { data, error } = useSWR<Session>(`/auth/user?is-installed=${isInstalled()}`, fetcher, {
        shouldRetryOnError: false,
    })

    useEffect(() => {
        if (!data) return
        let formattedData: Session = formatSessionData(data)
        if (error) navigate('/error/', { replace: true })
        if (!formattedData.isLoggedIn && !redirectIfFound) {
            navigate('/login/', { replace: true })
            return
        }
        if (redirectIfFound && formattedData.isLoggedIn) {
            navigate(redirectIfFound, { replace: true })
            return
        }
        requiredFeatures.forEach((f) => {
            if (!formattedData?.user?.feature_flags?.includes(f)) {
                navigate('/login/', { replace: true })
                return
            }
        })
    }, [data, redirectIfFound, error, requiredFeatures])

    return {
        session: formatSessionData(data),
    }
}

function formatSessionDataRevisor(data: SessionRevisor) {
    if (!data) return
    let formattedData: SessionRevisor = data
    if (data.user) {
        formattedData = {
            isLoggedIn: data.isLoggedIn,
            user: {
                user_id: data.user.user_id,
                name: data.user.name,
                is_mail_verified: Boolean(data.user.is_mail_verified),
                email: data.user.email,
                // feature_flags: data.user?.feature_flags,
                has_password: data.user.has_password ? Boolean(data.user.has_password) : false,
                is_advisor: data.user.is_advisor,
                current_advisor_period_end: data.user.current_advisor_period_end,
                has_paid: data.user.has_paid,
                company_name: data.user.company_name,
                signed_up_at: new Date(data.user.signed_up_at),
            },
        }
    }
    return formattedData
}

export const useAuthRevisor = (
    redirectIfFound = ''
    // requiredFeatures: string[] = [],
) => {
    const { data, error } = useSWR<SessionRevisor>('/auth-revisor/user', fetcherRevisor, {
        shouldRetryOnError: false,
    })

    useEffect(() => {
        if (!data) return
        let formattedData: SessionRevisor = formatSessionDataRevisor(data)
        if (error) navigate('/error/', { replace: true })
        if (!formattedData.isLoggedIn && !redirectIfFound) {
            navigate('/login-revisor/', { replace: true })
        }
        if (redirectIfFound && formattedData.isLoggedIn) {
            navigate(redirectIfFound, { replace: true })
        }
    }, [
        data,
        redirectIfFound,
        error,
        // requiredFeatures,
    ])

    return {
        session: formatSessionDataRevisor(data),
    }
}

export const useAuthAdmin = (
    redirectIfFound = ''
    // requiredFeatures: string[] = [],
) => {
    const { data, error } = useSWR<Session>('/ll-admin/user', fetcherAdmin, {
        shouldRetryOnError: false,
    })

    useEffect(() => {
        if (!data) return
        let formattedData: Session = formatSessionData(data)
        if (error) navigate('/error/', { replace: true })
        if (!formattedData.isLoggedIn && !redirectIfFound) {
            navigate('/ll-admin/login/', { replace: true })
        }
        if (redirectIfFound && formattedData.isLoggedIn) {
            navigate(redirectIfFound, { replace: true })
        }
    }, [
        data,
        redirectIfFound,
        error,
        // requiredFeatures,
    ])

    return {
        session: formatSessionData(data),
    }
}
